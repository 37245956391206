/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { useMaterialUIController } from "/context";
import dynamic from "next/dynamic";
import { robotoSlab } from "/utils/fonts";

const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });
const CollectionsRounded = dynamic(() => import("@mui/icons-material/CollectionsRounded"), { loading: () => null });
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });
const ALLDCard = dynamic(() => import('/examples/ALLDCard'), { loading: () => null });

function OurWork({ projectCards }) {
  const [controller] = useMaterialUIController();

  return (
    <>
      <MDBox component="section" py={7}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            justifyContent="center"
            mx="auto"
            sx={{ textAlign: "center" }}
          >
            <MDTypography variant="h2" color={controller.sidenavColor} textGradient className={robotoSlab.className}>
              Our Work
            </MDTypography>
            <MDTypography variant="h2" mb={2} className={robotoSlab.className}>
              50+ Projects to Review Online
            </MDTypography>
            <MDTypography variant="body1" color="text" mb={2}>
              Delivering resort-like atmosphere at homes for 30+ years.
            </MDTypography>
          </Grid>
          <br />
          <Grid container spacing={3}>
            {projectCards !== undefined && projectCards.length !== 0 && projectCards.map((row, index) => (
              <ALLDCard key={`our-work-${index}`} row={row} pathPrefix="/our-work/project" type="Project" />
            ))}
          </Grid>
          <MDBox textAlign="center">
            <MDButton
              href="/our-work"
              target="_blank"
              rel="noreferrer noopener"
              variant="contained"
              color="dark"
              startIcon={<CollectionsRounded />}
            >More Projects</MDButton>
          </MDBox>
        </Container>
      </MDBox>
    </>
  );
}

export default OurWork;
